body {
  font-family: "Overpass", sans-serif;
}

.section {
  z-index: 1000;

  margin: 20px 20px 20px 70px;

  height: 100vh;

  background-color: #f0f9fc;
  margin: 0px;
  padding: 0px;
}


#btnre {
  background-color: #ecf5fd;
  width: 100px;
  color: #4f9fe6;
  margin: 5px;
  border-radius: 5px;
  opacity: 1;
  border: none;
}

#btnre:hover {
  color: #ffffff;
  background-color: #4f9fe6;
}

#bids {
  background-color: #ecf5fd;
  color: #4f9fe6;
  margin: 5px;
  border-radius: 5px;
  opacity: 1;
}

#bids:hover {
  color: #ffffff;
  background-color: #4f9fe6;
}


#approval {
  color: #4f9fe6;
  margin-top: 15px;
  padding-bottom: 10px;
}

#approval:hover {
  color: #33778e;
}

.viewdetails {
  position: absolute;
  margin-left: 80px;
  width: 140px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 15px;
  justify-content: center;
  padding-top: 10px;

  padding-right: 10px;
  box-shadow: 0px 4px 16px #00000012;

  span {
    color: #66739b;
  }

  .aLink:hover {
    background-color: #f5f8fd;
    border-radius: 5px;
  }
}

.imgarrow {
  position: absolute;
  margin-left: 200px;
  margin-bottom: 10px;
  width: 40px;
}

.freights {
  position: absolute;
  margin-left: 30px;
  width: 190px;
  height: 240px;
  background-color: #ffffff;
  border-radius: 15px;
  justify-content: center;
  padding: 10px;

  // padding-right: 10px;
  box-shadow: 0px 4px 16px #00000012;

  .option {
    padding: 10px;
    padding-left: 0px;
    border-radius: 7px;
    text-align: left;
  }
}

dd {
  text-align: left;
  font: normal normal normal 14px/20px Overpass;
  letter-spacing: 0.42px;
  color: #66739b;
  opacity: 1;
}

.imgarrow {
  position: absolute;
  margin-left: 200px;
  margin-bottom: 10px;
  width: 40px;
}

.ongoing-dropdown {
  text-align: right;
  // margin-right: 130px;

  .ongoingarrow {
    position: absolute;
    left: 136px;
    bottom: 90px;
  }

  .dropdown-menu.show {
    margin-left: -118px !important;
  }

  ul {
    // margin-right: 100px;
    border-radius: 10px;
    border: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #00000012;
    transform: translate3d(-45px, 0px, 0px) !important;
  }

  button {
    color: #66739b;
    margin-right: 30px;
  }
}

.my-free {
  text-align: left;
  font: normal normal normal 16px/23px Overpass;
  letter-spacing: 0.48px;
  color: #2c3244;
  opacity: 1;
}


.requestarow {
  position: absolute;
  left: 136px;
  bottom: 36px;
}

.OfferVehicle {
  justify-content: right;
  text-align: right;
  text-align: left;


}

.bydefult {
  font: normal normal normal 14px/22px Overpass;
  letter-spacing: 0.34px;
  color: #66739b;
  opacity: 1;
  width: 100%;
  height: 100%;
  border: none;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px #00000008;
  border-radius: 7px;

}

.freights-card-status {
  text-align: left;

  button {
    border: none;
    background-color: transparent;
    font-size: 14px;
    letter-spacing: 0.34px;
    color: #D54F35;
    opacity: 1;
  }
}

@media(max-width:768px) {
  .modal-content {
    width: 95% !important;
  }
}

.ongoing-freights-side {
  width: 349px;
  min-width: auto;

  .View-Details-link {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.29px;
    color: #8a443d;
  }

  .inside {
    div {
      &:hover {
        background-color: #F8F6F2;
        transition: all 0.3s;
      }
    }
  }
}

.main-side-freight-menu {
  position: fixed;
  top: 60px;
  right: 0;
  background-color: #ffff;
  height: 100vh;
  // width: 350px;
  min-width: auto;
  z-index: 950;
  transition: all 0.5s;

  button {
    background-color: #ffff;
    position: absolute;
    border: none;
    border-radius: 20px 0 0 20px;
    left: -23px;
    padding: 4vh 3px;
    top: 44vh;
  }
}

.active-side-menu {
  transition: all 0.5s;
  position: fixed;
  top: 60px;
  right: -350px;
  background-color: #ffff;
  height: 100vh;
  width: 350px;
  min-width: auto;
  z-index: 950;


  button {
    background-color: #ffff;
    position: absolute;
    border: none;
    border-radius: 20px 0 0 20px;
    left: -23px;
    padding: 4vh 3px;
    top: 44vh;
  }
}

.FreightsDetails-live-menu {
  .main-body-live {
    width: 350px;
    min-width: auto;
    top: 60px;
    overflow-y: auto;

    .ontitle {
      font-size: 14px;
      letter-spacing: 0.34px;
      color: #1F1104;
      font-weight: 600;
      margin-bottom: 0;
    }

    .offcanvas-header{
      background: #8a443d0D 0% 0% no-repeat padding-box;
    }

    .topimg-live{
      height: 42px;
      width: 42px;
      border-radius: 50%;
    }

    label{
      font-size: 12px !important;
      letter-spacing: 0.29px;
color: #6C6864 !important;
    }
  }
}

.FreightsStatus-live-menu {
  .main-body-live {
    top: 60px;
    overflow-y: auto;
    width: 350px;
    min-width: auto;
  }
}