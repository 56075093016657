
body{
font-family: 'Overpass', sans-serif;


} @import url('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// font-family: 'Overpass', sans-serif;
#cx-sidebar-toggler{
    border-bottom:1px solid #367973;
    line-height:60px;
    padding-bottom: 5px;
    margin-top: 15px 0px 5px 0px;
    display:flex;
    align-items: center;
    

    #cx-sidebar-toggler button{
        // height:80px;
        // width:50px;
        background:transparent;
        border:0;
    }
    #cx-sidebar-toggler button svg{
        fill:#ffffff !important;
    }
    #cx-sidebar-toggler button:hover svg{
        fill:#ffffff !important;
    }
    #cx-sidebar-toggler.cx-active{
        justify-content: end;
        // padding-right:20px;
    }
    #cx-sidebar-toggler.cx-active button{
        width:100%;
        height: 100%;
        transform: rotate(180deg);
    }
}
.list{
justify-items: left;
margin-left: -30px;
.cx-nav-active{
    padding: 25px 20px;
    justify-content: right;

    
}
.Arrow_hidde{
   visibility: hidden;

}
#active:active{
    color:#ffffff ;

}
.parentActive{
    opacity: 1;
}
}
/*------------------------------------
            General Styles
------------------------------------*/
#cx-sidebar{
    position:fixed;
    top:0px;
    width:70px;
    height: 100%;
    background-color: #874841;
    transition: all 0.5s;
    white-space: nowrap;
    z-index:1010;
   justify-items: center !important;
   align-items: center ;
   
   
}


.cx-container{
    width:100%;

}
.cx-list li{
  
    font-size: 16px;
    position: relative;
    border-bottom:1px solid #AC6420;
    list-style-type: none;
    // text-align: left !important;
    transition: all 0.5s ease;

}

.cx-list li a{
    display:flex;
    // justify-content: center;
    align-items: center;
    width:100%;
    height:20px;
    transition: all 0.5s ease;
    background:transparent;
    position: relative;
    color:#ffffff;
    opacity: 0.4;
    text-decoration: none;

}

.cx-list li a span{
    display:none;
    text-decoration: none;

}

.cx-list.cx-active li a img{
    padding-right:10px;
}
.cx-list.cx-active li a span{
    display:block;
    position: relative;
    top:2px;
}
.cx-sidebar-brand.cx-active{
    justify-content: start;
    padding-left:20px;
}

.cx-brand-logo{
    width: 50px;
    margin: 10px 0px 0px 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #AC6420;

}
.cx-list li .cx-tooltip{
    padding: 0 10px;
    visibility: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    visibility: hidden;
    right: -190px;
    width: 200px;
    transition: all 0.5s ease;
    height: 40px;
    line-height: 40px;
    background: #ffffff;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 5%);
    border-radius: 5px;
    opacity: 0;
    z-index: 1001;
}
.cx-tooltip::after{
    content:'';
    position:absolute;
    top:12px;
    left:-7px;

    margin: auto;
    width: 0; 
    height: 0; 
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #8a443d;
}
.cx-list li:hover .cx-tooltip{
    opacity:1;
    visibility: visible;
    transition: all 0.5s ease;

}
.cx-list li .cx-tooltip:hover{
    opacity:0;
    visibility: visible;
    transition: all 0.5s ease;

}

/*------------------------------------
                Toggler
------------------------------------*/
#cx-sidebar-toggler{

    border-bottom:1px solid #AC6420;
    display:flex;
    justify-content: center;
    padding: 20px 10px;

}
#cx-sidebar-toggler button  {
    opacity: 0.65;
    background:transparent;
    border:0;
   
}
#cx-sidebar-toggler button svg{
    fill:#ffffff !important;
}
#cx-sidebar-toggler button:hover svg{
    fill:#ffffff !important;
}
#cx-sidebar-toggler.cx-active{
    justify-content: end;
    padding-right:26px;
}
#cx-sidebar-toggler.cx-active button{
    width:100%;
    transform: rotate(180deg);
}

#example-collapse-text{
    transition: all 0.5s ease;

  
    .ul{
    list-style: none;
    list-style-type: none;
        margin: 0;
        padding: 0;
        transition: all 0.5s ease;

    }
    .mgt{
        padding: 20px 18px 18px 22px;
        justify-self: center;
        text-align: center;
        transition: all 0.5s ease;

    }
    .good{
        padding: 20px 30px 18px 10px;
        justify-self: center;
        text-align: center;
        transition: all 0.5s ease;

    }
        
}
.list{
    margin-top: 0px;
    ul{

    }
    
}
/*------------------------------------
               Active Styles
------------------------------------*/
#cx-sidebar.cx-active{
    width:270px; 
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    // overflow-x: hidden;
    .brandbox{
        border-bottom: 1px solid #AC6420;
        border-right: 1px solid #AC6420;
    }
    .logoActive{
            width: 50px;
            margin: 10px 0px 0px 15px;
            padding-bottom: 10px;
           
        }
    .brandActive{
        color: #ffffff;
    }
     
    .cx-brand-logo{
      display: none;
        
    }
    
.icon_arrow{
    display: flex;
    align-items: center;
    color:#ffffff;
    justify-content: space-between;
}
.active{
    color: #ffffff;
}
#icon_Show{
    visibility: visible;
   

}
}
.cx-list.cx-active li Link{
    text-align: left;
    justify-content: left;
    transition: all 0.5s ease;

   
}
.cx-list.cx-active li a .cx-list-c{
    right:unset;
    left:12px;
}
.cx-list li:active{
    background-color: #97BAC6;
    border-left: 6px solid #97BAC6;
    transition: all 0.5s ease;

}


.cx-list li:hover{
    background-color: white transparent;
    transition: all 0.5s ease;

}
.cx-list li a:hover{
    color:#ffffff ;
    opacity:1;
    transition: all 0.5s ease;


}



.cx-list.cx-active li a span{
    // width:100% !important;
    opacity:1;
    display: block;
    position: relative;
    top: 2px;

}
.cx-sidebar-brand.cx-active {
    text-align: left;   
}
.cx-sidebar-brand.cx-active a .cx-brand-icon {
    margin-right:15px;
}
.cx-list.cx-active li .cx-tooltip{
    display:none;
}




/*------------------------------------
            Media queries
------------------------------------*/

@media(max-width:556px){
    #cx-sidebar{
        position:fixed;
        // top:unset;
        overflow-y: auto;
        overflow-x: hidden;
        // left:0;
        // bottom:0;
        // height:48px;    
        // padding-top:0;
        // width:100%;
        // transition: all .3s;
        white-space: nowrap;
        // z-index:1010;
    .cx-brand-logo{
        // display: none;
    }
        
    }
    .cx-container{
        height:100%;
    }
    
    #cx-sidebar-toggler{
        // display:none;
    }
    .cx-list{
        // display:flex;
        align-items: center;
        height:100%;
    }
    .cx-list li{
        // display:inline-block;
        flex-grow:1;
    }
    .cx-list li a {
        // height:48px;
    }
    .cx-nav-active{
        justify-content: right;
        
    }
}

.innerMenu-border{
    li{
        border-bottom: 1px solid #AC6420;
    }
}

.cx-sidebar-brand{
    position: sticky;
    z-index: 2100;
    top: 0;
    background: #874841;
}