@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@400;600;700;800;900&display=swap');
@import '../css/mixin.scss';
@import '../css/variables.scss';

* {
    scrollbar-width: thin;
    scrollbar-color: #874841 #F5F5F5;

}

body {
    margin: 0;
    padding: 0;
    font-family: 'Overpass', sans-serif;
    overflow: auto !important;

}


label {
    color: #898E8E !important;
    font-size: 14px !important;
    letter-spacing: 0.48px;
    font-family: "Overpass", sans-serif;
}

p {
    color: #1F1104;
    font-size: 16px;
}

select {
    @include selectBox;
}

input {
    @include inputBox;
}

textarea {
    resize: none;
    border-radius: 7px !important;
    border: 1px solid $inputBox-borderColor !important;

}



.form-check-input {
    background: #8a443d33 0% 0% no-repeat padding-box;
    border-radius: 5px !important;
    opacity: 1;
    height: 16px;
    width: 16px;
    border: none !important;
}

.form-check-input:checked {
    background-color: #874841 !important;
}

.form-check-input:checked[type=checkbox] {
    //    background-image: url(../images/tick_green.svg) !important;


    // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e);
}

hr {
    height: 1px;
    color: #F6F2EF;
    margin: 0.2rem 0.5rem;
}


::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    // display: none;
    height: 5px;
    width: 5px;
    background-color: #F5F5F5;

}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #874841;


}



@media all and (min-width: 768px) {
    .cx-active-sidebar {
        margin-left: 290px !important;
        transition: all 0.5s ease-in-out;
    }

    .cx-active-sidebarB {
        margin-left: 270px !important;
        transition: all 0.5s ease-in-out;
    }
}

input[type="file"] {
    display: none;
}

.btn-approve {
    color: #FFFFFF;
    border: none;
    background: #4FBFA4 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding: 5px 40px;
    font-size: 14px;
    font-weight: 600;
    flex-basis: 80%;
    width: 95%;
}

.btn-remove {
    background: #4FBFA419 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    padding: 3px 10px;
    flex-basis: 20%;
    width: 100%;
}

.vehicle-request-img {
    height: 42px;
    width: 42px;
    border-radius: 50%;
}

.offered-by {
    text-align: left;
    font: normal normal 600 14px/22px Overpass;
    letter-spacing: 0.34px;
    color: #4FBFA4;
    opacity: 1;
}

.card-label {
    text-align: left;
    font: normal normal normal 14px/20px Overpass !important;
    letter-spacing: 0.42px;
    color: #898E8E;
    opacity: 1;
}

.small-card-data {
    text-align: left;
    font: normal normal 600 14px/22px Overpass;
    letter-spacing: 0.34px;
    color: #191A1A;
    opacity: 1;
}

.popup-approve-btn {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.38px;
    color: #8a443d;
    border: 1px solid #8a443d;
    border-radius: 5px;
    opacity: 1;
    padding: 5px 15px;
    background: transparent;

    &:hover {
        transition: all ease-out 0.3s;
        background: #8a443d 0% 0% no-repeat padding-box;
        border: none;
        border: 1px solid #8a443d;

        a {
            color: #FFFFFF !important;
        }
    }
}

.popup-reject-btn {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.38px;
    color: #8a443d;
    border: 1px solid #8a443d;
    border-radius: 5px;
    background: transparent;
    opacity: 1;
    padding: 5px 15px;

    &:hover {
        transition: all ease-out 0.3s;
        background: #8a443d 0% 0% no-repeat padding-box;
        border: 1px solid #8a443d;
        color: #FFFFFF;
    }
}

.TransportersAssigned-btn {
    border: none;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.34px;
    color: #BE8300;
    opacity: 1;
    background: transparent;
}

.WaitingforTransportersResponse-btn {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.34px;
    color: #898E8E;
    opacity: 1;
    border: none;
    background: transparent;
}

.green-link-btn {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.34px;
    color: #4FBFA4;
    opacity: 1;
    border: none;
    background: transparent;
}

.fix-offer-vehicles {
    .ViewSpecifications-link {

        letter-spacing: 0.34px;
        color: #4FBFA4;
        font-size: 14px;
        font-weight: 600;
    }

    label {
        font-size: 12px;
    }

    p {
        font-size: 14px;
        font-weight: 600;
    }

    margin-left: -20px;
    position: fixed;
    bottom: 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -4px 16px #0076ec1a;
    opacity: 1;
    height: 140px;
    width: 100%;
    overflow: auto;
}

.dropdown-menu.show {
    // inset: 4px -117px auto auto !important;
}

.modal-dialog-centered {
    justify-content: center;
}

a {}

.active-arrowAndColor {
    opacity: 1;
    display: block;
}

img {
    vertical-align: middle;
}

table.dataTable thead th,
table.dataTable thead td {
    border: none !important;
}

table.dataTable {
    border: none !important;
    border-spacing: 0 10px !important;
}

table.dataTable tbody th,
table.dataTable tbody td {
    padding: 10px !important;
}

table.dataTable thead th,
table.dataTable thead td {
    padding: 10px !important;

}

table.dataTable thead,
th {
    font-size: 12px !important;
}

table.dataTable thead .sorting {
    background-image: none !important;
}

table.dataTable>thead .sorting:before,
table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_desc:before,
table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_desc_disabled:before {
    display: none !important;
}

table.dataTable>thead .sorting:after,
table.dataTable>thead .sorting_asc:after,
table.dataTable>thead .sorting_desc:after,
table.dataTable>thead .sorting_asc_disabled:after,
table.dataTable>thead .sorting_desc_disabled:after {
    display: none !important;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc {
    background: none !important;
    background-repeat: no-repeat !important;
}

.dataTables_paginate .paginate_button.current {
    color: #ffff !important;
    border: none !important;
    background: #4FBFA4 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    color: #ffff !important;
    border: none !important;
    // background: #4FBFA4 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: #ffff !important;
    border: none !important;
    background: #8a443d !important;
}


table.dataTable thead .sorting_asc {
    background: none !important;
    background-repeat: no-repeat !important;
}

.dataTables_wrapper .dataTables_length {
    float: right !important;
}

.dataTables_wrapper .dataTables_filter {
    float: left !important;
}

.dataTables_wrapper .dataTables_filter input {
    background-color: #ffff !important;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
    color: red;
}

.dataTables_wrapper .dataTables_info {
    color: black !important;
    padding: 10px;
    border-radius: 5px;
    background: #8a443d19 !important;
    font-size: 16px;
    font-weight: bold;
}

div.dataTables_wrapper div.dataTables_length select {
    width: 74px !important;
    height: 36px !important;
    background-color: #ffff !important;
}

div.dataTables_wrapper div.dataTables_filter input {
    height: 36px;
    width: 250px;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
    margin-bottom: 30px !important;
}

// ========= Dropdown Menu ============

.option-dropdown ul {

    button {
        color: #66739B;
        font-size: 12px;

        border-radius: 5px;

        &:hover {
            background-color: #8a443d1A;


            letter-spacing: 0.36px;
            color: #8a443d;

        }
    }

    padding: 10px;
}

#dropdown-transporterList {

    .arrowvehicle {
        position: absolute;
        left: 135px;
        bottom: 126px;
    }
}

#dropdown-ShiperList {
    .arrowvehicle {
        position: absolute;
        left: 135px;
        bottom: 103px;
    }

}

.dropdown-menu.show {
    position: absolute;
    inset: -29px 29px auto auto !important;
    margin: 0px;
    transform: translate3d(-8px, 32px, 0px) !important;
}

#transporterDetail-dropdown {
    .arrowvehicle {
        position: absolute;
        left: 135px;
        bottom: 128px;
    }

    .dropdown-menu.show {
        inset: -13px 46px auto auto !important;
    }
}

#vehicleDetail-dropdown {
    .arrowvehicle {
        position: absolute;
        left: 135px;
        bottom: 126px;
    }

    .dropdown-menu.show {
        inset: -34px 25px auto auto !important;
    }
}

table label,
th,
thead {
    font-size: 12px !important;
}

table p,
td,
tbody {
    font-size: 14px !important;
    font-weight: 600;
}

.change-driver-list {
    font-size: 14px;
    letter-spacing: 0.42px;
    color: #191A1A;
    opacity: 1;
}

.btn-change-driver {
    background: #4FBFA4 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #ffff;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        color: #ffff;
    }
}

.direct-order-main {
    .nav-pills .nav-link {
        margin-top: 7px;
    }
}

.card-heading-order-confirmation {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.56px;
    color: #874841;
    background: #8748410D 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 7px 15px;
}

.order-confirm-table {
    .tr-body {
        background: #F7F7F7 0% 0% no-repeat padding-box;
        border-radius: 7px;

        p {
            margin-bottom: 0 !important;
            padding: 5px;
        }
    }

}

.create-auction-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;

    .card {
        width: 400px;
        height: auto;

    }

    a {}

    .auction-btn {
        width: 100%;
        border: none;
        background: #4FBFA4 0% 0% no-repeat padding-box;
        font-weight: 600;
        color: #ffff;
        font-size: 16px;
        letter-spacing: 0.38px;
        border-radius: 8px;
        padding: 5px;
    }
}

.freights-search-select-main {

    .freights-search-select {
        flex-basis: 25%;
    }
}

.vehicle-detail-live {
    .arrowvehicle {
        bottom: 100px !important;
    }
}


.icons {
    &:hover {
        background-color: #8a443d19 !important;
    }
}

.uploading {
    p {
        color: #B4ACA5 !important;
    }
}

.status-VehicleRequestSent {
    color: #F73333 !important;
    text-decoration: underline;
}

.status-SeeVehicleOffers {
    color: #8a443d;
    text-decoration: underline;

    &:hover {
        color: #8a443d !important;
    }
}

.status-Approve {
    color: #26A300 !important;
    text-decoration: underline;
}

.details {
    p {
        font-size: 14px;
        font-weight: 600;
    }

    label {
        font-size: 12px !important;
    }
}

.confirm-detail-heading {
    font-weight: bold;
    letter-spacing: 0.56px;
    font-size: 14px;
    color: #D54F35;
    background-color: #8a443d19;
    padding: 7px 10px;
    border-radius: 5px;
}

.t-row-confirm {
    background-color: #FDFAF8;
}

.accept-remove-btn {
    text-decoration: none;
    background-color: #8a443d1A;
    color: #8a443d;
    padding: 5px 10px;
    margin-left: 15px;
    border-radius: 5px;

    &:hover {
        color: #8a443d !important;
    }
}

.heading-select-transporter {
    font-size: 14px;
    letter-spacing: 0.42px;
    color: #1F1104;
    font-weight: bold;
}

.select-transporter {
    .name-driver {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.42px;
        color: #1F1104;
        margin-bottom: -2px;
    }

    .rating {
        img {
            width: 14px;
        }

        span {
            font-size: 14px;
            color: #1F1104;
            font-weight: 500;
        }
    }
}

.Vehicle-popup-offered {
    .modal-content {
        padding: 20px;
        border-radius: 15px;
        background: #FDFEFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 16px #00788C0D;

    }

    p {
        font-size: 14px;
        font-weight: 600;
    }

    label {
        font-size: 12px !important;
    }

    .name-vehicle {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.48px;
        color: #1F1104;
    }
}

.offergoodslist-table {
    label {
        font-weight: 600;
        color: #6C6864 !important;
    }

    td {
        font: normal normal normal 14px/22px Overpass;
        letter-spacing: 0.34px;
        color: #1F1104;
    }
}

.vehicle-offer-direct-order-table {
    label {
        font: normal normal 600 12px/18px Overpass;
        letter-spacing: 0.19px;
        color: #6C6864 !important;
    }

    td {
        font: normal normal normal 14px/22px Overpass;
        letter-spacing: 0.22px;
        color: #1F1104;
        opacity: 1;
    }
}

.btn-position-style {}

.modal-header .btn-close {
    padding: 0px 20px 0 0 !important;
    height: 10px !important;
    width: 10px !important;
}

.accordion-button::after {
    background-image: url(../images/ic_dropdown.svg) !important;
}

.offcanvas-header .btn-close {
    padding: 5px !important;
    height: 5px !important;
    width: 5px !important;
}

.Rowbgcolor{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #2614070D;
    border-radius: 5px;
}

.option-dropdown .arrowvehicle {
    bottom: 48px;
    left: 135px;
    position: absolute;
}