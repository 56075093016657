@import '../css/mixin.scss';
@import '../css/variables.scss';


.header-body {
    label{
        font-size: 12px !important;
      }
    transition: all 0.5s ease-in-out;
    margin-left: 70px;
    height: 48px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 16px #00000008;

    .view-details {
        transition: all 0.5s ease-in-out;

        text-decoration: none;
        margin-left: 15px;
        text-align: left;
        font-size:  bold 14px ;
        letter-spacing: 0.42px;
        color: #4FBFA4;
    }

}

.tab-link {

    font-size: 14px ;
    font-weight: 600;
    letter-spacing: 0.42px;
    color: #B4ACA5;
    opacity: 1;


    &:hover{
        color: #8a443d !important;
    }
}